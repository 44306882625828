import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import * as landingActions from './landing.actions';
import { Router } from '@angular/router';
import { Claim } from '@wam/shared';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { GrantService } from '@app/claims/grant.service';
import { ProfilePicturesService } from '@app/shared/profile-pictures.service';

@Injectable()
export class LandingEffects {
  selectParent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(landingActions.selectParent),
        tap(() => this.router.navigateByUrl('/parent-home')),
      ),
    { dispatch: false },
  );

  loadClaims$ = createEffect(() =>
    this.actions$.pipe(
      ofType(landingActions.loadClaimsUpstartLanding),
      mergeMap(() =>
        this.grantService.loadClaims().pipe(
          switchMap((claims: Claim[]) => [
            landingActions.loadClaimsSuccess({ claims }),
            landingActions.loadProfilePictures({ studentIds: claims.map((c) => c.resourceId) }),
          ]),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 404) {
              return of(landingActions.loadClaimsSuccess({ claims: [] }));
            } else {
              return of(landingActions.loadClaimsFail({ error: error.message }));
            }
          }),
        ),
      ),
    ),
  );

  loadProfilePictures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(landingActions.loadProfilePictures),
      mergeMap(({ studentIds }) =>
        this.profilePicturesService
          .getProfilePictures(studentIds)
          .pipe(
            map((profilePictures) =>
              landingActions.loadProfilePicturesSuccess({ profilePictures }),
            ),
          ),
      ),
    ),
  );

  selectClaim$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(landingActions.selectClaim),
        tap(() => this.router.navigateByUrl('/new-student-home')),
      ),
    { dispatch: false },
  );

  clearSelectedClaim$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(landingActions.clearSelectedClaim),
        tap(() => localStorage.removeItem('selectedClaim')),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private grantService: GrantService,
    private profilePicturesService: ProfilePicturesService,
  ) {}
}
