import { createReducer, on } from '@ngrx/store';

import { LandingState } from '@app/shared/state/landing.state';
import * as landingActions from './landing.actions';
import * as claimsActions from '@app/claims/state/claims.actions';

const initialState: LandingState = {
  parentSelected: false,
  claims: null,
  profilePictures: null,
  selectedClaim: null,
};

export const landingReducer = createReducer(
  initialState,
  on(landingActions.selectParent, (state) => ({
    ...state,
    parentSelected: true,
  })),
  on(landingActions.loadClaimsUpstartLanding, (state) => ({
    ...state,
  })),
  on(claimsActions.loadClaimsSuccess, (state, { claims }) => ({
    ...state,
    claims,
  })),
  on(landingActions.loadProfilePicturesSuccess, (state, { profilePictures }) => ({
    ...state,
    profilePictures,
  })),
  on(landingActions.selectClaim, (state, { claim }) => ({
    ...state,
    selectedClaim: claim,
  })),
  on(landingActions.clearSelectedClaim, (state) => ({
    ...state,
    selectedClaim: null,
  })),
);

export function reducer(state, action) {
  return landingReducer(state, action);
}
