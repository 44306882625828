import { Injectable } from '@angular/core';
import { State } from '@app/shared/index';
import { mergeMap, Observable } from 'rxjs';
import { ApiGatewayService } from '@wam/authentication';
import { Store } from '@ngrx/store';
import { WithAuthService } from '@app/shared/services/with-auth.service';
import { ProfilePictureMapping } from '@wap/profile-picture';

@Injectable({
  providedIn: 'root',
})
export class ProfilePicturesService extends WithAuthService {
  constructor(
    private apiGatewayService: ApiGatewayService,
    public store: Store<State>,
  ) {
    super(store);
  }

  getProfilePictures(students: string[]): Observable<ProfilePictureMapping[]> {
    const studentIds = JSON.stringify(students);
    return this.withUser().pipe(
      mergeMap((user) =>
        this.apiGatewayService.get<ProfilePictureMapping[]>(
          `profile-picture/v1/users/${user.cognitoId}/mappings?studentIds=${studentIds}`,
        ),
      ),
    );
  }
}
